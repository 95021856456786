/************************ Default CSS ***************************/
html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
}

*,
::after,
::before {
    box-sizing: border-box;
}

a:focus,
a {
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

body {
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;
    font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;

}

/************************ Preview Page CSS ***************************/
.mobile-app-area {
    background-image: url(../image/bg6.jpg);
    background-size: cover;
    background-position: center;
    padding: 80px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.main-title {
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 70px;
}

.point-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.point-list li {
    width: 50%;
    list-style: none;
    padding: 2px 0 2px 40px;
    font-size: 18px;
    color: #fff;
    position: relative;
    margin-top: 12px;
}

.phoneWrapper {
    width: 390px;
    height: 730px;
    border-radius: 40px;
    background: #ffd700;
    border: 10px solid #ffd700;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.phoneWrapper .in {
    width: 100%;
    height: 100%;
    background: #ffd700;
    border-radius: 24px;
    overflow: hidden;
}

.phoneWrapper .in .getFrame {
    width: 100%;
    height: 100%;
    border: 0 !important;
    padding: 0;
    margin: 0;
}

.phoneContent {
    width: 390px;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.phoneContent-main {
    display: flex;
    justify-content: flex-end;
}

.align-items-center {
    align-items: center !important;
}

.mobile-app-area:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1), rgb(0 0 0 / 80%), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.point-list li:after {
    content: "";
    width: 20px;
    position: absolute;
    left: 0;
    height: 20px;
    top: 5px;
    background-image: url(../image/check.png);
    background-size: cover;
}

.preview-page-btn {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.pre-btn {
    width: 150px;
    height: 50px;
    background: #00D061;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.pre-btn a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
}

img {
    vertical-align: middle;
    border-style: none;
}

.btn-success img {
    width: 22px;
    padding-right: 5px;
}

.mt-50 {
    margin-top: 50px;
}

.scan-area {
    font-size: 12px;
    width: 250px;
    height: auto;
    background: #fff;
    text-align: center;
    border-radius: 12px;
    padding: 30px 20px;
    box-shadow: 0 4px 40px 0 rgba(32, 0, 61, 0.05);
    margin-top: 50px;
}

.scan-area .scan img {
    width: 100%;
}

.scan-area .scan {
    padding: 0;
    width: 150px;
    display: inline-block;
    margin: 0 auto 5px;
}

.scan-area p {
    font-size: 16px;
    color: #121212;
    line-height: 1.4;
    text-transform: capitalize;
    margin: 0;
    font-family: Lato;
}

.featured-top-sec h2 {
    color: #121212;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    padding-top: 20px !IMPORTANT;
    text-transform: uppercase;
}

.inner-bottom-sec {
    margin-top: 60px;
}

.featured-top-sec p {
    color: #0A1D35;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
}

.featured-bottom-wrap {
    background: #F6F8FA;
    padding: 50px;
    transition: all 0.5s ease;
    border-radius: 15px;
    box-shadow: 0px -4px 4px rgba(30, 41, 59, 0.04);
}

.featured-bottom-wrap h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1E293B;
    margin-top: 12px;
}

.featured-bottom-sec {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    grid-gap: 15px;
    margin-top: 50px;
}

img.featured-img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 64px;
    width: 64px;
}

.featured-section {
    background: #f1f5f9;
    padding: 100px;
    position: relative;
}

#mobile-content {
    padding: 100px 0px;
}

.particles-js-canvas-el {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.footer-txt1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.row.footer-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brookwood-footer {
    background: #1E293B;
    padding: 15px;
}

a.text-white {
    color: white;
}

.footer-txt1:hover .text-white {
    color: #00D061;
}

.pre-btn.pre-btn1 {
    background: #FFFFFF;
}

.pre-btn.pre-btn1 a {
    color: #00D061;
    text-decoration: none;
}

/************************Dark Mode Button ***************************/
.dz-mode {
    background: #00D061;
    transform: scale(1.2);
    width: 34px;
    height: 62px;
    bottom: 100px;
    right: -50px;
    z-index: 999;
    color: yellow;
    border-radius: 28px;
    padding: 3px;
    transition: all 0.5s;
    cursor: pointer;
}

.dz-mode svg {
    z-index: 2;
    width: 16px;
    height: 28px;
    line-height: 20px;
    display: block;
    text-align: center;
    transition: all 0.5s;
    margin: auto;
}

.dz-mode svg:first-child {
    color: #000;
}

.dz-mode:after {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 28px;
    position: absolute;
    left: 3px;
    top: 3px;
    background: #fff;
    z-index: -1;
    transition: all 0.5s;
}

.dz-mode.light .sun path {
    fill: #00D061;
}

.dz-mode.light .moon path {
    fill: #fff;
}

.dz-mode.dark {
    background: #5c5c5c;
}

.dz-mode.dark:after {
    top: 31px;
}

.title {
    font-size: 18px;
    color: #fff;
    position: relative;
    margin-top: 12px;
    font-family: Lato;
    margin-bottom: 30px;
}

.arrow-bottom {
    animation: shake 1s ease infinite;
    position: absolute;
    right: 10px;
    top: 70px;
}

@keyframes shake {

    0%,
    10%,
    100%,
    20%,
    50%,
    80% {
        transform: translateX(0)
    }

    40%,
    60% {
        transform: translateX(-6px)
    }
}

.demo-button-section {
    position: relative;
}

.demo-button {
    position: absolute;
    right: -60px;
    bottom: 100px;
}

.mobile-content-bottom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 60px;
}

.media img {
    border-radius: 8px;
}

.content-box {
    margin-top: 25px;
}

.content-box p {
    margin-top: 10px;
    color: #707070;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.content-box h4 {
    color: #121212;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

#copyright-sec {
    position: relative;
    background: #000;
}

.footer-line {
    border-top: 1px solid #FFF;
    padding: 30px 0;
}

.copyright-txt {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.copyright-txt a {
    color: white;
}

.copyright-txt:hover .footer-txt2 a {
    color: #00D061;
}

.footer-txt2 a {
    text-decoration: none;
    margin-left: 5px;
}

/************************ Device setting CSS ***************************/
.main-list {
    background-color: #fff;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin: 0;
    padding: 35px;
    display: block;
    align-items: center;
    width: 300px;
    position: absolute;
    bottom: 0;
    left: -725px;
}

.main-list .size-list {
    display: flex;
    align-items: flex-end;
    margin: 15px -10px 0;
    padding-left: 0 !important;
}

.size-list li {
    list-style: none;
}

.main-list .size-list li a {
    display: block;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    line-height: 1.3;
    color: #666666;
    font-family: Lato;
    text-decoration: none;
}

.main-list .size-list li.active a {
    color: #00D061;
}

.main-list .size-list li a svg {
    margin-bottom: 10px;
}

.title-device {
    font-size: 22px;
    color: #00D061;
    position: relative;
}

.main-list:after {
    content: "";
    position: absolute;
    top: 40px;
    right: -10px;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 4px;
    transform: rotate(-45deg);
}

.device-setting-sec {
    position: relative;
}

.phoneWrapper.frame-rounded {
    border-radius: 35px;
}

.phoneWrapper.frame-lg {
    width: 410px;
    height: 830px;
}

.phoneWrapper.frame-solid {
    border-radius: 4px;
}

.main-list .size-list li.active svg path,
.main-list .size-list li.active svg rect {
    stroke: #00D061;
}

.main-list .size-list li a svg rect {
    stroke: #666666;
}

/************************ Fr CSS ***************************/
.switch.style-2 {
    top: 120px;
    height: 100px;
    background-color: #ffd700;
    width: 6px;
    right: -6px;
}

.switch {
    content: "";
    position: absolute;
    background-color: #ffd700;
    top: 70px;
    right: -5px;
    border-radius: 0 10px 10px 0;
    width: 5px;
    height: 65px;
}

.slick-slide {
    width: 275px !important;
    margin: 0 24px;

}

.volume.style-2 {
    box-shadow: 0px 85px 0 0px #ffd700;
    background-color: #ffd700;
    top: 120px;
    left: -7px;
    width: 7px;
    height: 66px;
}

.volume {
    content: "";
    position: absolute;
    background-color: #00D061;
    top: 70px;
    left: -5px;
    border-radius: 10px 0 0 10px;
    box-shadow: 0px 60px 0 0px #2c3f6d;
    width: 5px;
    height: 45px;
}

/************************ Slider CSS ***************************/
.page-section {
    position: relative;
}

.layout-mobile {
    background-color: #000;
    cursor: pointer;
    border-radius: 30px;
    overflow: hidden;
    padding: 8px;
}

.label-layout {
    overflow: unset;
}

.volume {
    content: "";
    position: absolute;
    background-color: #000;
    top: 70px;
    left: -5px;
    border-radius: 10px 0 0 10px;
    box-shadow: 0px 60px 0 0px #000;
    width: 5px;
    height: 45px;
}

.switch {
    content: "";
    position: absolute;
    background-color: #000;
    top: 70px;
    right: -5px;
    border-radius: 0 10px 10px 0;
    width: 5px;
    height: 65px;
}

.page-section.slick-slide {
    margin: 0 15px;
}

.layout-mobile img {
    border-radius: 22px;
    width: 100%;
}

.label-layout .color-label.default {
    background-color: #EC417F;
    box-shadow: 0px 10px 15px 0px rgba(236, 65, 127, 0.31);
}

.label-layout .color-label {
    position: absolute;
    bottom: 0px;
    left: 50%;
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    width: auto;
    min-width: 85px;
    text-align: center;
    transform: translateX(-50%);
    background-color: #4cb32b;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
}

.label-layout .color-label:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    z-index: -1;
    background-color: transparent;
    transform: translateX(-50%) rotate(-45deg);
}

.label-layout .color-label.default:after {
    background-color: #EC417F;
}

.layout-mobile img {
    display: block;
}

.inner-bottom-sec-full .slick-custom-arrow {
    position: absolute;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border: 2px solid #121212;
    cursor: pointer;
    color: #121212;
}

.inner-bottom-sec-full .slick-custom-arrow-left {
    left: -6%;
    top: 50%;
}

.inner-bottom-sec-full .slick-custom-arrow-right {
    right: -6%;
    top: 50%;
}

i.fa-solid.fa-arrow-right,
i.fa-solid.fa-arrow-left {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #121212;
}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev {
    left: -60px !important;
}

.slick-next {
    right: -20px !important;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px !important;
    line-height: 1;
    opacity: .75;
    color: black !important;
}

/************************ Details Section CSS ***************************/
.project-detail-top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

#project-detail {
    padding: 100px 0;
    background: #000;
}

.categary-box {
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #FFF;
}

.categary-box h4 {
    color: white;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px !important;
}

.categary-box p {
    color: #adb5bdeb;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.view-btn {
    padding: 18px 32px;
    border: 2px solid white;
    color: #121212;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: inline-block;
    margin-top: 40px;
    background: white;
}

.detail-btn {
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    padding: 8px 8px 8px 20px;
    border: 1px solid #94B0D5;
    width: 100%;
    text-decoration: none;
}

.detail-btn .text {
    padding: 0 25px 0 18px;
    text-align: left;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    font-family: Lato;
}

.package-box {
    border-radius: 10px;
    border: 1px solid rgba(251, 251, 251, 0.2);
    padding: 30px 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.footer-title {
    margin-bottom: 0;
    font-size: 14px;
    color: #fff;
    padding-right: 1px;
    font-family: Lato;
}

.figure {
    font-size: 70px;
    line-height: 1;
    color: #fff;
    font-weight: 100;
    /* margin-top: 8px; */
    /* margin-right: 20px; */
}

.figure img {
    height: 120px;
    width: 55px;
}

.package-list {
    display: flex;
    flex: 1;
    max-width: 400px;
    align-items: center;
}

.package-list ul li {
    background: rgba(251, 251, 251, 0.2);
    border: 1px solid rgba(251, 251, 251, 0.2);
    font-size: 12px;
    padding: 5px 8px;
    line-height: 1.6;
    border-radius: 4px;
    margin-bottom: 4px;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    margin-top: 4px;
    margin-right: 4px;
    font-family: Lato;
}

.project-detail-bottom {
    margin-top: 40px;
}

.visit-box h2,
.guarantee h2 {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.visit-box h2 {
    margin-top: 20px !important;
}

.guarantee h2 {
    margin-bottom: 20px !important;
}

.visit-box {
    text-align: center;
    padding: 40px;
    height: 100%;
    border: 1px solid #94B0D5;
}

.visit-box a {
    text-decoration: none !important;
}

.guarantee {
    text-align: center;
    margin-top: 20px !important;
}

.guarantee p {
    color: #FF484D;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.title-box h3 {
    margin-top: 20px !important;
    font-family: Lato;
}

.text-danger {
    color: #00D061 !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 15px !important;
}

span.value {
    color: #94b0d5;
}

.detail-btn .value {
    background-color: white;
    color: #121212;
    border-radius: 5px;
    width: 80px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-family: Lato;
}

.view-btn:hover {
    background: transparent;
    border: 2px solid white;
    color: white !important;
}

.visit-box img {
    border-radius: 40px;
}

.phoneWrapper.frame-solid .in {
    border-radius: 4px;
}